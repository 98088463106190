@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.root {
  position: relative;
  text-align: left;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 64px);

  @include mobile {
    height: calc(var(--vh, 1vh) * 100 - 74px);
  }
}

.gameBg {
  object-fit: cover;
  object-position: var(--game-background-pos-x) 100%;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 64px);

  @include mobile {
    height: calc(var(--vh, 1vh) * 100 - 74px);
  }
}

.phaser {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100vw;
}

.hpBar {
  position: absolute;
  top: 244px;
  right: 140px;
  z-index: 4;

  width: 203px;
  height: 88px;

  @include mobile {
    top: 148px;
    right: 20px;
    width: 98px;
    height: 42.5px;
  }
}

.timer {
  position: absolute;
  top: 144px;
  right: 140px;
  z-index: 4;

  &__img {
    width: 203px;
    height: 88px;

    @include mobile {
      width: 98px;
      height: 42.5px;
    }
  }

  &__text {
    @include typo-subtitle1-black;
    color: var(--museum-color-gray-3);
    text-align: center;
    position: absolute;
    width: 114px;
    height: 30px;
    top: 31px;
    right: 79px;

    @include mobile {
      @include typo-body2-black;
      text-align: center;
      top: 13px;
      left: 0;
      width: 67px;
      height: 15px;
    }
  }

  @include mobile {
    top: 92px;
    right: 20px;
    width: 97px;
    height: 40px;
  }
}

.scoreBar {
  position: absolute;
  top: 144px;
  left: 140px;
  z-index: 4;

  &__img {
    width: 203px;
    height: 88px;

    @include mobile {
      width: 98px;
      height: 42.5px;
    }
  }
  &__text {
    @include typo-subtitle1-black;
    color: var(--museum-color-gray-3);
    text-align: center;
    position: absolute;
    width: 114px;
    height: 30px;
    top: 31px;
    left: 79px;

    @include mobile {
      @include typo-body2-black;
      text-align: center;
      top: 13px;
      left: 40px;
      width: 57px;
      height: 15px;
    }
  }

  @include mobile {
    top: 92px;
    left: 20px;
    width: 97px;
    height: 40px;
  }
}
