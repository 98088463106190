@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

@mixin front {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100 - 64px);
  width: 100%;
  z-index: 5;

  @include mobile {
    height: calc(var(--vh, 1vh) * 100 - 74px);
  }
}

.background {
  @include front;
  opacity: 0.5;
  z-index: 2;
}

.front {
  @include front;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head{
  @include typo-h6-bold();
  border-radius: 30px 30px 0 0;
  text-align: center;
  padding: 20px;
  width: 440px;
  border-bottom: solid 2px var(--museum-color-gray-3);

  @include mobile {
    @include typo-subtitle2-bold();
    padding: 16px 12px;
    text-align: center;
    width: 257px;
  }
}
.body{
  @include typo-subtitle1-bold();
  border-radius: 0 0 30px 30px;
  padding: 40px 40px 40px 40px;
  width: 440px;

  @include mobile {
    @include typo-body1-bold();
    padding: 28px 36px;
    width: 257px;
  }
}

