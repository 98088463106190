@mixin typo-h1-bold {
  //styleName: Header/H1/bold;
  // font-family: Noto Sans CJK TC;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 144px;
  font-style: normal;
  font-weight: 700;
  line-height: 216px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h2-black {
  //styleName: Header/H2/black;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 96px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h2-bold {
  //styleName: Header/H2/bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h3-bold {
  //styleName: Header/H3/bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h4-bold {
  //styleName: Header/H4/bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h4-medium {
  //styleName: Header/H4/Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h5-bold {
  //styleName: Header/H5/bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h5-medium {
  //styleName: Header/H5/Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 1px;
  text-align: left;
}

@mixin typo-h6-bold {
  //styleName: Header/H6/bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-h6-medium {
  //styleName: Header/H6/Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-subtitle1-black {
  //styleName: Subtitle/Subtitle1/Black;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-subtitle1-bold {
  //styleName: Subtitle1/ Bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-subtitle1-medium {
  //styleName: Subtitle1/ Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-subtitle2-bold {
  //styleName: Subtitle/Subtitle2/Bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-subtitle2-medium {
  //styleName: Subtitle/Subtitle2/Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-body1-bold {
  //styleName: Body/Body1/Bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-body1-medium {
  //styleName: Body/Body1/Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-body2-black {
  //styleName: Body/Body2/Black;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: left;
}
@mixin typo-body2-bold {
  //styleName: Body/Body2/Bold;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: left;
}

@mixin typo-body2-medium {
  //styleName: Body/Body2/Medium;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: left;
}
