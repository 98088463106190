@use './color.scss';

* {
  box-sizing: border-box;
  font-family: 'Noto Sans CJK TC', 'PingFang TC', '微軟正黑體', sans-serif;
  outline: none;
  touch-action: manipulation;
}

html,
body {
  padding: 0;
  font-size: 1em;
  margin: 0;
}

body {
  // for modal
  position: relative;
  width: 100vw;
}

button {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a::after,
a::before {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

img,
img::after,
img::before {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}
