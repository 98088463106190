@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.modalFront {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
}

.titleLogo {
  width: 538px;
  height: 381px;

  @include mobile {
    width: 296px;
    height: 209px;
  }
}

.gameOverContainer {
  display: flex;
  flex-direction: column;
  margin: 74px 50px 0px 50px;
}

.header {
  position: relative;
  height: 74px;

  @include mobile {
    height: 44px;
    // width: 257px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: -50px;
    height: 148px;
    width: 540px;

    @include mobile {
      left: -31px;
      width: 320px;
      height: 88.6px;
    }
  }
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;
  width: 440px;
  height: 200px;

  @include mobile {
    width: 257px;
    height: auto;
  }

  background: var(--museum-color-gray-1);
  border-radius: 30px;
  border: 5px solid var(--museum-color-orange-3);

  text-align: center;

  &__message {
    @include typo-subtitle1-bold;
    color: var(--museum-color-gray-3);
    text-align: center;

    @include mobile {
      @include typo-body2-bold;
      text-align: center;
    }
  }
}
