:root {
  --museum-color-gray-1: #f0f0f0;
  --museum-color-gray-2: #aaaeb5;
  --museum-color-gray-3: #5e6066;
  --museum-color-black: #212224;
  --museum-color-white: #ffffff;
  --museum-color-ctbc-red-1: #fd615d;
  --museum-color-ctbc-red-2: #e92d31;
  --museum-color-ctbc-green-1: #f1ffff;
  --museum-color-ctbc-green-2: #00a5a5;
  --museum-color-ctbc-green-3: #007c7c;
  --museum-color-green-1: #b2d472;
  --museum-color-green-2: #8fbc39;
  --museum-color-green-3: #658528;
  --museum-color-green-4: #405419;
  --museum-color-blue-1: #ccdee1;
  --museum-color-blue-2: #9fc0c6;
  --museum-color-blue-3: #6c9fa8;
  --museum-color-blue-4: #4a767d;
  --museum-color-yellow-1: #ffee8e;
  --museum-color-yellow-2: #ffe351;
  --museum-color-yellow-3: #ffd600;
  --museum-color-orange-1: #ffdfba;
  --museum-color-orange-2: #ffba68;
  --museum-color-orange-3: #ff971c;
  --museum-color-brown-1: #efd4b0;
  --museum-color-brown-2: #db9c4a;
  --museum-color-brown-3: #b07323;
  --museum-color-brown-4: #704916;
  --museum-color-red-1: #ffa494;
  --museum-color-red-2: #d35c5a;
  --museum-color-red-3: #ae302e;
  --museum-color-red-4: #7e2321;
}
