@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.root {
  display: flex;
  flex-direction: column;
}

.img {
  width: 538px;
  height: 381px;
  margin: 0 0 120px 0;

  @include mobile {
    width: 296px;
    height: 209px;
    margin: 0 0 80px 0;
  }
}

.button {
  @include typo-h6-bold;
  text-align: center;

  width: 231px;
  height: 52px;

  @include mobile {
    @include typo-subtitle2-bold;
    text-align: center;

    width: 195px;
    height: 50px;
  }
}