@import '@@themes/mobile.scss';

.soundButton {
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 5;

  right: 140px;
  bottom: 110px;

  &__img {
    width: 60px;
    height: 60px;
  }

  @include mobile {
    right: 24px;
    bottom: 98px;
    width: 40px;
    height: 40px;

    &__img {
      width: 40px;
      height: 40px;
    }
  }
}