@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.head {
  color: var(--museum-color-white);
  background-color: var(--museum-color-orange-3);
}

.body {
  background-color: var(--museum-color-gray-1);
  margin-bottom: 60px;
  text-align: center;

  @include mobile {
    margin-bottom: 32px;
  }

  &__text {
    color: var(--museum-color-gray-3);
    @include typo-subtitle1-bold;
    text-align: justify;
    margin-bottom: 32px;

    @include mobile {
      @include typo-body1-bold;
      text-align: justify;
    }
  }
  &__img {
    max-height: 60px;
  }
}

.button {
  @include typo-h6-bold();
  text-align: center;
  color: var(--museum-color-white);

  width: 231px;
  height: 52px;

  @include mobile {
    @include typo-subtitle2-bold();
    text-align: center;
    width: 195px;
    height: 50px;
  }
}
