@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.head {
  color: var(--museum-color-white);
  background-color: var(--museum-color-orange-3);
}

.body {
  color: var(--museum-color-gray-3);
  background-color: var(--museum-color-gray-1);
  padding: 48px 40px 46px 40px;

  @include mobile {
    padding: 35px 40px 37px 40px;
  }
}

.ageButton {
  @include typo-h6-bold();
  color: var(--museum-color-gray-3);
  width: 100%;
  height: 52px;
  text-align: center;

  &:active, &:hover {
    color: var(--museum-color-black);
  }

  @include mobile {
    @include typo-subtitle2-bold();
    width: 100%;
    height: 50px;
    text-align: center;
  }
}

.buttonGroup {
  display: grid;
  gap: 32px 0;

  @include mobile {
    gap: 26px 0;
  }
}
