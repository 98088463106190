@import '@@themes/mobile.scss';

.root {
  position: relative;
  text-align: center;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

.logo {
  position: absolute;
  top: 40px;
  left: 141px;
  width: 171px;
  height: 44px;
  z-index: 3;

  @include mobile {
    top: 40px;
    left: 20px;
    width: 124.8px;
    height: 32px;
  }
}
