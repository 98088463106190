@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 140px 24px 140px;

  @include mobile {
    display: grid;
    justify-content: center;
    gap: 6px;
    text-align: center;
    padding: 12px 20px 16px 20px;
  }
}

.text {
  position: relative;
  @include typo-body2-bold;
  white-space: nowrap;
  color: var(--museum-color-gray-2);

  @include mobile {
    text-align: center;
  }
}

.linkWithAnimate {
  position: relative;
  @include typo-body2-bold;

  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    content: '';
    height: 0;
    width: 0;
    border-bottom: solid 1px var(--museum-color-gray-3);
  }

  &:link, &:visited, &:active {
    color: var(--museum-color-gray-2);
  }

  &:hover {
    color: var(--museum-color-gray-2);
    &::after {
      width: 100%;
      transition: width 0.75s;
    }
  }
}

.linkActive {
  color: var(--museum-color-gray-3);

  &:link, &:visited, &:active, &:hover {
    color: var(--museum-color-gray-3);
  }

  &::after {
    content: '';
    width: 100%;
  }
}

.divider {
  padding: 0 13px;
}

.floatBox {
  position: absolute;
  z-index: 11;
  white-space: normal;
  word-wrap: break-word;
  color: var(--museum-color-gray-1);
  background-color: var(--museum-color-ctbc-green-2);
  bottom: 40px;
  right: 80.85px;
  padding: 20px;
  border-radius: 16px 16px 0px 16px;

  @include mobile {
    text-align: left;
    right: 0px;
  }

  &__body2m {
    @include typo-body2-medium;
  }

  &__link {
    &:link, &:visited, &:hover, &:active {
      color: var(--museum-color-gray-1);
    }
  }

  &__Sharp {
    position: absolute;
    bottom: -20px;
    right: 0px;
    width: 0;
    height: 0;
    border: solid 10px;
    border-color: var(--museum-color-ctbc-green-2) var(--museum-color-ctbc-green-2) transparent transparent;
  }
}

.floatBoxBg {
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  bottom: 0px;
  z-index: 10;
}
