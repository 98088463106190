@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.root {
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  height: 74px;
  width: 440px;

  @include mobile {
    height: 44px;
    width: 257px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: -50px;
    height: 148px;
    width: 540px;

    @include mobile {
      left: -31px;
      width: 320px;
      height: 88.6px;
    }
  }

}
.body {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 395px;
  padding: 0;


  background: var(--museum-color-gray-1);
  border-radius: 30px 30px 0px 0px;
  border-top: 5px solid var(--museum-color-orange-3);
  border-left: 5px solid var(--museum-color-orange-3);
  border-right: 5px solid var(--museum-color-orange-3);
  border-bottom: 5px dashed var(--museum-color-orange-3);

  @include mobile {
    height: 295px;
    width: 257px;
    border-top: 3px solid var(--museum-color-orange-3);
    border-left: 3px solid var(--museum-color-orange-3);
    border-right: 3px solid var(--museum-color-orange-3);
    border-bottom: 3px dashed var(--museum-color-orange-3);
  }


  &__stars {
    margin: 60px 84px 20px 84px;
    display: flex;
    justify-content: space-around;

    @include mobile  {
      margin: 36px 36.51px 0 36.51px
    }

    &__child {
      width: 53px;
      height: 50px;

      @include mobile  {
        width: 37px;
        height: 35px;
      }
    }
  }

  &__score {
    margin: 0 auto 20px auto;
    width: 79px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--museum-color-ctbc-red-1);
    &__number {
      @include typo-h2-black;
      padding-right: 10px;

      @include mobile {
        @include typo-h3-bold;
      };
    }
    &__suffix {
      @include typo-subtitle1-black;
      @include mobile {
        @include typo-body1-bold;
      };
    }

    @include mobile {
      width: 84px;
      height: 72px;
      margin: 0 auto 0 auto;
    }
  }

  &__message {
    @include typo-subtitle1-bold;
    color: var(--museum-color-gray-3);
    text-align: center;
    margin: 0 0 40px 0;

    @include mobile {
      @include typo-body2-bold;
      text-align: center;
      margin: 0 0 16px 0;
    }
  }

  &__buttonGroup {
    display: flex;
    justify-content: center;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    &__button {
      width: 178px;
      height: 40px;
      margin: 0 10px;
      @include typo-body1-bold;
      text-align: center;

      @include mobile {
        @include typo-body2-bold;
        text-align: center;
        height: 36px;
        margin: 0 0 18px 0;
      }
    }
  }
}
.footer {
  height: 170px;
  padding: 28px 6.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  background: var(--museum-color-gray-1);
  border-radius: 0px 0px 30px 30px;
  border-left: 5px solid var(--museum-color-orange-3);
  border-right: 5px solid var(--museum-color-orange-3);
  border-bottom: 5px solid var(--museum-color-orange-3);

  @include mobile {
    height: 104px;
    padding: 16px 0;
    border-left: 3px solid var(--museum-color-orange-3);
    border-right: 3px solid var(--museum-color-orange-3);
    border-bottom: 3px solid var(--museum-color-orange-3);
  }

  &__text {
    @include typo-body1-bold;
    text-align: center;
    color: var(--museum-color-gray-3);
    margin-bottom: 30px;

    @include mobile {
      @include typo-body2-bold;
      text-align: center;
      margin: 0 0 12px 0;
    }
  }

  &__imgContainer {
    display: grid;
    grid-template-columns: auto auto;
    gap: 60px;
    justify-content: center;
    text-align: center;

    @include mobile {
      gap: 40px;
    }

    &__link {
      position: relative;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      @include mobile {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.img {
  position: absolute;
  max-height: 60px;
  left: 0px;
  &:hover {
    transition: all 0.08s;
    left: -5px;
    max-height: 70px;
  }

  @include mobile {
    max-height: 40px;
    &:hover {
      transition: all 0.08s;
      left: -5px;
      max-height: 50px;
    }
  }
}