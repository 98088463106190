@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.head {
  position: relative;
  color: var(--museum-color-white);
  background-color: var(--museum-color-orange-3);
}

.body {
  background-color: var(--museum-color-gray-1);
  color: var(--museum-color-gray-3);

  &__text {
    @include typo-subtitle1-bold;
    text-align: justify;
    margin-bottom: 40px;

    @include mobile {
      @include typo-body1-bold;
      text-align: justify;
      margin-bottom: 32px;
    }
  }

  &__coinGroup {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 40px;
    justify-content: center;
    @include mobile {
      gap: 0;
      justify-content: space-between;
    }

    &__coin {
      text-align: center;
      &__icon {
        max-height: 60px;
        margin: 0 0 12px 0;
        @include mobile {
          max-height: 40px;
          margin: 0 0 8px 0;
        }
      }
      &__text {
        white-space: nowrap;
        @include typo-subtitle2-bold;
        text-align: center;

        @include mobile {
          @include typo-body2-bold;
          text-align: center;
        }
      }
    }
  }
}

.button {
  @include typo-h6-bold();
  color: var(--museum-color-white);

  margin-top: 60px;
  width: 231px;
  height: 52px;
  text-align: center;

  background-color: var(--museum-color-ctbc-green-2);
  box-shadow: 0 6px var(--museum-color-ctbc-green-3);

  &:hover {
    background-color: var(--museum-color-ctbc-green-2);
  }
  &:active {
    background-color: var(--museum-color-ctbc-green-2);
    transform: translateY(3px);
    box-shadow: 0 3px var(--museum-color-ctbc-green-3);
  }

  @include mobile {
    @include typo-subtitle2-bold;
    margin-top: 32px;
    width: 195px;
    height: 50px;
    text-align: center;
  }
}
