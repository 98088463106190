@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.head {
  color: var(--museum-color-white);
  background-color: var(--museum-color-ctbc-green-2);
}

.body {
  color: var(--museum-color-gray-3);
  background-color: var(--museum-color-gray-1);

  .bodyText {
    @include typo-subtitle1-bold();
    text-align: justify;

    @include mobile {
      @include typo-body1-bold();
      text-align: justify;
    }

    &__aa {
      font-size: 50px;
    }

    .link {
      &:link, &:visited, &:hover, &:active {
        color: var(--museum-color-ctbc-green-2);
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
      }
    }
  }
}

.button {
  @include typo-h6-bold();
  margin-top: 60px;
  width: 231px;
  height: 52px;
  text-align: center;

  @include mobile {
    @include typo-subtitle2-bold();
    height: 50px;
    margin-top: 40px;
    text-align: center;
    width: 195px;
  }
}
