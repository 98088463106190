@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.head {
  color: var(--museum-color-white);
  background-color: var(--museum-color-orange-3);
}

.body {
  color: var(--museum-color-gray-3);
  background-color: var(--museum-color-gray-1);

  .bodyText {
    @include typo-subtitle1-bold;
    margin-bottom: 32px;
    text-align: justify;

    @include mobile {
      @include typo-body1-bold();
      text-align: justify;
      margin-bottom: 16px;
    }
  }

  .bodyFooter {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
    justify-content: center;

    .img {
      max-height: 100px;

      @include mobile {
        max-height: 60px;
      }
    }
  }
}

.button {
  @include typo-h6-bold();
  color: var(--museum-color-white);
  height: 52px;
  margin-top: 60px;
  text-align: center;
  width: 231px;

  background-color: var(--museum-color-ctbc-green-2);
  box-shadow: 0 6px var(--museum-color-ctbc-green-3);

  &:hover {
    background-color: var(--museum-color-ctbc-green-2);
  }
  &:active {
    background-color: var(--museum-color-ctbc-green-2);
    transform: translateY(3px);
    box-shadow: 0 3px var(--museum-color-ctbc-green-3);
  }

  @include mobile {
    @include typo-subtitle2-bold();
    height: 50px;
    margin-top: 40px;
    text-align: center;
    width: 195px;
  }
}
