@import '@@themes/typography.scss';
@import '@@themes/mobile.scss';

.root{
  position: relative;
}

.animateButton {
  @include typo-body1-bold();
  text-align: center;

  width: 360px;
  height: 40px;
  border-radius: 50px;
  padding: 8px, 48px, 8px, 48px;

  outline: none;
  border: none;

  background-color: var(--museum-color-orange-1);
  box-shadow: 0 6px var(--museum-color-orange-3);

  &:hover {
    background-color: var(--museum-color-orange-2);
  }
  &:active {
    background-color: var(--museum-color-orange-2);
    transform: translateY(3px);
    transition-duration: 0.20s;
    box-shadow: 0 3px var(--museum-color-orange-3);
  }

  @include mobile {
    box-shadow: 0 5px var(--museum-color-orange-3);
  }
}

.templateCtbcGreen {
  color: var(--museum-color-white);

  background-color: var(--museum-color-ctbc-green-2);
  box-shadow: 0 6px var(--museum-color-ctbc-green-3);
  &:hover {
    background-color: var(--museum-color-ctbc-green-2);
  }
  &:active {
    background-color: var(--museum-color-ctbc-green-2);
    box-shadow: 0 3px var(--museum-color-ctbc-green-3);
  }
  @include mobile {
    box-shadow: 0 5px var(--museum-color-ctbc-green-3);
  }
}
